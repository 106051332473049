export const workshopsData = [
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/miniwreaath2.webp",
    ],
    title: "Countryman's favours",
    subtitle:
      "A short introduction to plaiting straw. Including preparation, tying and two or three simple plaits. It’s a great idea for pre-wedding parties (‘favours’ make great buttonholes). Suitable for ages 10+.",
  },
  {
    photoSrc: ["https://strawcraft.s3.eu-west-2.amazonaws.com/hat2.webp"],
    title: "Corsage or straw hat decoration",
    subtitle:
      "Learn a couple of plaiting techniques and get an introduction to Swiss Straw Work. Suitable for ages 12+.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/guides/IMG_2541.webp",
    ],
    title: "Straw christmas angels",
    subtitle:
      "Introduces two types of plaiting technique plus tied straw techniques. Suitable for ages 12+.",
  },
  {
    photoSrc: ["https://strawcraft.s3.eu-west-2.amazonaws.com/candles.webp"],
    title: "Five straw spiral plaits",
    subtitle:
      "A more advanced workshop for those with some straw craft experience or good crafting skills!",
  },
];
