import styles from "./App.module.css";
import Content from "./Content/Content.js";
import PastEvents from "./PastEvents/PastEvents.js";
import React, { useState, useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";
import Workshops from "./Workshops/Workshops.js";
import handleEmailClick from "./Utils/Utils.js";

function App() {
  const [selectedButton, setSelectedButton] = useState("Projects");
  const [isSticky, setIsSticky] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  const handleSelectButton = (button) => {
    setSelectedButton(button);
  };

  useEffect(() => {
    const pageSelection = document.querySelector(`.${styles.pageSelection}`);
    const stickyWithShadowClass = styles["sticky-with-shadow"];

    const handleScroll = () => {
      const rect = pageSelection?.getBoundingClientRect();

      if (rect?.top <= 0 && !isSticky) {
        setIsSticky(true);
        pageSelection.classList.add(stickyWithShadowClass);
      } else if (rect?.top > 0 && isSticky) {
        setIsSticky(false);
        pageSelection.classList.remove(stickyWithShadowClass);
      }
    };

    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
    };
  }, [isSticky]);

  return (
    <div className={styles.App}>
      <Analytics />
      <div className={styles.headerAndIntro}>
        <h1 className={styles.header}>Wheatear Strawcraft</h1>
        <div className={styles.intro}>
          I have been working with straw for many years. Straw work is an
          ancient craft with traditions reaching across the globe. My particular
          interest is with corn dollies, Swiss straw work and straw marquetry.
          In particular I enjoy bringing a contemporary twist to traditional
          designs. Working with a natural product allows creative expression. It
          is a low impact, sustainable craft.
          <br></br>
          <br></br>
          Straw craft is currently listed as endangered by Heritage Crafts. I
          wish to promote the craft through workshops, which can be tailored to
          suit complete beginners. I aim to bring a mindful experience to the
          workshops and events with which I’m involved.
          <br></br>
          <br></br>
          Contact by email:{" "}
          <span onClick={handleEmailClick} className={styles.email}>
            ianspeechley@btinternet.com
          </span>
        </div>
      </div>
      {!isDesktop && (
        <div
          className={`${styles.pageSelection} ${styles.sticky} ${
            isSticky ? styles["sticky-with-shadow"] : ""
          }`}
        >
          <div
            className={`${styles.page} ${
              selectedButton === "Projects" ? styles.selected : ""
            }`}
            onClick={() => handleSelectButton("Projects")}
          >
            Projects
          </div>
          |
          <div
            className={`${styles.page} ${
              selectedButton === "Workshops" ? styles.selected : ""
            }`}
            onClick={() => handleSelectButton("Workshops")}
          >
            Workshops
          </div>
          |
          <div
            className={`${styles.page} ${
              selectedButton === "Past Events" ? styles.selected : ""
            }`}
            onClick={() => handleSelectButton("Past Events")}
          >
            Past Events
          </div>
        </div>
      )}
      <div className={styles.contentContainer}>
        {isDesktop && (
          <div className={styles.pageSelectionDesktop}>
            <div
              className={`${styles.page} ${
                selectedButton === "Projects" ? styles.selected : ""
              }`}
              onClick={() => handleSelectButton("Projects")}
            >
              Projects
            </div>
            |
            <div
              className={`${styles.page} ${
                selectedButton === "Workshops" ? styles.selected : ""
              }`}
              onClick={() => handleSelectButton("Workshops")}
            >
              Workshops
            </div>
            |
            <div
              className={`${styles.page} ${
                selectedButton === "Past Events" ? styles.selected : ""
              }`}
              onClick={() => handleSelectButton("Past Events")}
            >
              Past Events
            </div>
          </div>
        )}
        {selectedButton === "Projects" ? <Content /> : null}
        {selectedButton === "Workshops" ? <Workshops /> : null}
        {selectedButton === "Past Events" ? <PastEvents /> : null}
      </div>
      <div className={styles.footer}>
        Website and photography | Matthew Speechley 2023
      </div>
    </div>
  );
}

export default App;
