export const pastEventsData = [
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/homeworkshop/IMG_2537.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/homeworkshop/IMG_2659.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/homeworkshop/IMG_2664.webp",
    ],
    title: "Christmas Straw Angel Workshop",
    date: "Autumn 2024",
    subtitle:
      "This workshop demonstrated two different straw plaits and then combined with 'tied straw work' to produce a straw angel for the christmas tree.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/guides/IMG_0004.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/guides/IMG_2543.webp",
    ],
    title: "Enstone Guides",
    date: "Autumn 2024",
    subtitle:
      "A workshop with The Enstone Guides. During the session pupils produced 'compass plait' and 'hair-plait' favours.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/farmed2/IMG_2535.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/farmed2/IMG_2547.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/farmed2/IMG_2534.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/farmed2/IMG_2536.webp",
    ],
    title: "FarmED - Harvest Festival",
    date: "Autumn 2024",
    subtitle:
      "A workshop with FarmED, celebrating Harvest Festival on their 107 acre demonstration farm in the Cotswolds. Over 200 harvest favours were produced during the day. [Find out more here.](https://www.farm-ed.co.uk/news/43/harvest-festival)",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/workshop3.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/workshop1.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/workshop4.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/workshop2.webp",
    ],
    title: "'As I was A-Walking'",
    date: "Summer 2024",
    subtitle:
      "A workshop in conjunction with Chipping Norton Theatre and FarmED.",
  },
];
